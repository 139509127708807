/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import {
    WorkflowCurrentStateSteps, WorkflowStateEnum, WorkflowStateEnumType, WorkflowStatePayload,
    WorkflowType,
} from '../../../types/workflows';
import Button from '../Button';
import { ButtonVariant } from '../../../types/general';
import { ReactComponent as RaisingIcon } from '../../../assets/images/raising.svg';
import { ReactComponent as PendingAprovalIcon } from '../../../assets/images/pending_aproval.svg';
import { ReactComponent as SellingNegotiationIcon } from '../../../assets/images/selling_negotiation.svg';
import { ReactComponent as PromisoryAgreementIcon } from '../../../assets/images/promissory_agreement.svg';
import { ReactComponent as ListedIcon } from '../../../assets/images/megaphone.svg';
import { ReactComponent as DeedIcon } from '../../../assets/images/deed.svg';
import { ReactComponent as ClosedIcon } from '../../../assets/images/closed.svg';
import Modal from '../Modal';
import { FormField } from '../FormField';

type OwnProps = TranslationContext & WorkflowContext & {
    workflowType: WorkflowType;
    availableSteps: WorkflowCurrentStateSteps;
};

const ActionButtonsBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        workflowType,
        availableSteps,
        changeWorkflowState,
    } = props;

    const { workflowId = '' } = useParams();
    const navigate = useNavigate();
    
    const [payload, setPayload] = useState<WorkflowStatePayload | null>(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    /**
     *  Return icon jsx based on the received state
     * @param state
     * @returns
     */
    const getIcon = (state: WorkflowStateEnumType) => {
        switch (state) {
            case WorkflowStateEnum.SELL_DOCUMENTS_APPROVAL:
            case WorkflowStateEnum.RENTAL_DOCUMENTS_APPROVAL:
            case WorkflowStateEnum.SELL_PENDING_APPROVAL:
            case WorkflowStateEnum.RENTAL_PENDING_APPROVAL:
                return <PendingAprovalIcon />;
            case WorkflowStateEnum.RENTAL_LISTED:
                return <ListedIcon />;
            case WorkflowStateEnum.SELL_NEGOTIATION:
            case WorkflowStateEnum.RENTAL_NEGOTIATION:
                return <SellingNegotiationIcon />;
            case WorkflowStateEnum.SELL_BUYING_PROMISSORY_AGREEMENT:
            case WorkflowStateEnum.RENTAL_PROPOSAL_APPROVED:
                return <PromisoryAgreementIcon />;
            case WorkflowStateEnum.SELL_DEED:
                return <DeedIcon />;
            case WorkflowStateEnum.SELL_CLOSED:
            case WorkflowStateEnum.RENTAL_RENTED:
                return <ClosedIcon />;
            case WorkflowStateEnum.SELL_RAISING:
            default:
                return <RaisingIcon />;
        }
    };

    /**
     * Sets selected state and open confirmation modal
     *
     * @param newState
     */
    const handleOpenModal = (state: WorkflowStateEnumType) => {
        setPayload((prevValue) => ({
            state,
            notes: prevValue?.notes ?? '',
        }));
        setOpenConfirmModal(true);
    };

    /**
     * Reset default values and close modal
     */
    const handleCloseModal = () => {
        setPayload(null);
        setOpenConfirmModal(false);
    };

    /**
     * Change Workflow State
     *
     * @param newState
     *
     * POST request to change this Workflow state to new provided state
     */
    const requestWorkflowStateChange = async () => {
        if (!payload) return;

        const requestError = await changeWorkflowState(workflowId, payload);

        if (requestError) {
            toast.error(requestError.errors[0].getMessageTranslated(t));
            return;
        }
        
        // Refresh screen
        navigate(0);
    };
    
    return (
        <div className="workflow-screen--view__general-info__action-buttons">
            {availableSteps.nextState && (
                <Button
                    variant={ButtonVariant.Curved}
                    extraClasses="primary full-width"
                    testId="next-step-button"
                    startIcon={getIcon(availableSteps.nextState)}
                    onClick={() => handleOpenModal(availableSteps.nextState)}
                >
                    {t(`workflows.view.${workflowType}.nextStep.${availableSteps.nextState}`)}
                </Button>
            )}
            {availableSteps.previousState && (
                <Button
                    variant={ButtonVariant.Curved}
                    extraClasses="secondary full-width"
                    testId="previous-step-button"
                    startIcon={getIcon(availableSteps.previousState)}
                    onClick={() => handleOpenModal(availableSteps.previousState)}
                >
                    {t(`workflows.view.${workflowType}.previousStep.${availableSteps.previousState}`)}
                </Button>
            )}
            <Modal
                title={t('workflows.view.changeStateTitle')}
                open={openConfirmModal}
                handleClose={handleCloseModal}
                handleConfirm={requestWorkflowStateChange}
            >
                <p>{t('workflows.view.stateConfirmationText', { state: t(`workflows.view.${workflowType}.steps.${String(payload?.state)}`) })}</p>
                <FormField
                    name="note"
                    label={t('workflows.view.note')}
                    placeholder={t('workflows.view.notePlaceholder')}
                    onChange={(_, value) => setPayload((prevValue) => ({
                        ...prevValue!,
                        notes: value,
                    }))}
                />
            </Modal>
        </div>
    );
};

export const ActionButtons = withTranslationContext(withWorkflowContext(ActionButtonsBase));
